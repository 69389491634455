<template>
  <div>
    <el-dialog
      title="会员高级查询"
      :visible.sync="dialogTableVisible"
      width="900px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form ref="form" label-width="82px" size="mini">
        <el-row :gutter="24">
          <el-col class="title">基础信息</el-col>
        </el-row>
        <el-row :gutter="24">
          <!-- left -->
          <el-col :span="13">
            <el-form-item label="性别">
              <el-radio-group v-model="submitData.MemberSex">
                <el-radio :label="0">女</el-radio>
                <el-radio :label="1">男</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="卡属性">
              <el-select
                v-model="submitData.NatureNo"
                placeholder="请选择卡属性"
                @change="changeCardNature"
                clearable
              >
                <el-option
                  v-for="(item, index) in advancedQueryObject.cardNatureList"
                  :key="item.NatureNo"
                  :label="item.NatureName"
                  :value="item.NatureNo"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="卡分类">
              <el-select
                v-model="submitData.KindGuid"
                placeholder="请选择卡分类"
                @change="changeCardKind"
                clearable
              >
                <el-option
                  v-for="(item, index) in advancedQueryObject.cardKindList"
                  :key="index"
                  :label="item.KindName"
                  :value="item.KindGuid"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="卡名称">
              <el-select
                v-model="submitData.CardGuid"
                placeholder="请选择卡名称"
                clearable
              >
                <el-option
                  v-for="(item, index) in cardNameList"
                  :key="index"
                  :label="item.CardName"
                  :value="item.CardGuid"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="开卡日期">
              <div class="date">
                <el-date-picker
                  v-model="openDate"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                  @change="changeOpenDate"
                >
                </el-date-picker>
              </div>
            </el-form-item>
            <el-form-item label="有效期">
              <div>
                <el-radio-group v-model="submitData.ValidDateMode">
                  <el-radio :label="0">全部</el-radio>
                  <el-radio :label="1">已过期</el-radio>
                  <el-radio :label="2">在有效期</el-radio>
                  <el-radio :label="3">自定义</el-radio>
                </el-radio-group>
              </div>
            </el-form-item>
            <el-form-item v-if="submitData.ValidDateMode == 3" label="自定义">
              <div class="date">
                <!--  -->
                <el-date-picker
                  v-model="validDate"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                  @change="changeValidDate"
                >
                </el-date-picker>
              </div>
            </el-form-item>
          </el-col>

          <!-- right -->
          <el-col :span="9">
            <el-form-item label="卡状态">
              <el-select
                v-model="submitData.VipState"
                placeholder="请选择卡状态"
                clearable
              >
                <el-option
                  v-for="(item, index) in advancedQueryObject.vipState"
                  :key="'state_' + index"
                  :label="item.Name"
                  :value="item.Value"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <template v-if="submitData.NatureNo != '0002'">
              <el-form-item label="卡金余额">
                <div class="money">
                  <div>
                    <DFInput
                      v-model="submitData.StartKeepBalance"
                      placeholder="开始余额"
                    ></DFInput>
                  </div>
                  <div>至</div>
                  <div>
                    <DFInput
                      v-model="submitData.EndKeepBalance"
                      placeholder="结束余额"
                    ></DFInput>
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="赠金余额">
                <div class="money">
                  <div>
                    <DFInput
                      v-model="submitData.StartDonaBalance"
                      placeholder="开始余额"
                    ></DFInput>
                  </div>
                  <div>至</div>
                  <div>
                    <DFInput
                      v-model="submitData.EndDonaBalance"
                      placeholder="结束余额"
                    ></DFInput>
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="总充值金额">
                <div class="money">
                  <div>
                    <DFInput
                      v-model="submitData.StartCumuKeepMoney"
                      placeholder="开始金额"
                    ></DFInput>
                  </div>
                  <div>至</div>
                  <div>
                    <DFInput
                      v-model="submitData.EndCumuKeepMoney"
                      placeholder="结束金额"
                    ></DFInput>
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="总消费金额">
                <div class="money">
                  <div>
                    <DFInput
                      v-model="submitData.StartCumuExpeMoney"
                      placeholder="开始金额"
                    ></DFInput>
                  </div>
                  <div>至</div>
                  <div>
                    <DFInput
                      v-model="submitData.EndCumuExpeMoney"
                      placeholder="结束金额"
                    ></DFInput>
                  </div>
                </div>
              </el-form-item>
            </template>
            <template v-else>
              <el-form-item label="剩余消耗额">
                <div class="money">
                  <div>
                    <DFInput
                      v-model="submitData.StartPackBalance"
                      placeholder="开始余额"
                    ></DFInput>
                  </div>
                  <div>至</div>
                  <div>
                    <DFInput
                      v-model="submitData.EndPackBalance"
                      placeholder="结束余额"
                    ></DFInput>
                  </div>
                </div>
              </el-form-item>

              <el-form-item label="用完不显示">
                <div class="date">
                  <el-switch
                    v-model="submitData.HidePackOver"
                    :active-value="1"
                    :inactive-value="0"
                  >
                  </el-switch>
                </div>
              </el-form-item>
            </template>

            <el-form-item label="最后消费">
              <div class="date">
                <el-date-picker
                  v-model="lastDate"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                  @change="changeLastDate"
                >
                </el-date-picker>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24" class="title">其他信息</el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="允许跨店">
              <el-radio-group v-model="submitData.StoresShare">
                <el-radio :label="0">否</el-radio>
                <el-radio :label="1">是</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeAdvancedQueryDialog" size="small"
          >取 消</el-button
        >
        <el-button @click="submitForm('formRules')" size="small" type="primary"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import member from "@/api/member.js";
export default {
  data() {
    return {
      dialogTableVisible: false,
      openDate: [],
      lastDate: [],
      validDate: [],
      submitData: {},
      cardNature: "",
      advancedQueryObject: {},
      cardNameList: [],
    };
  },
  methods: {
    openAdvancedQueryDialog(event, initData) {
      this.submitData = event;
      this.advancedQueryObject = initData;
      this.cardNameList = initData.cardNameList;
      this.dialogTableVisible = true;
    },

    closeAdvancedQueryDialog() {
      this.dialogTableVisible = false;
    },

    changeOpenDate(event) {
      this.submitData.StartOpenDate = event[0];
      this.submitData.EndOpenDate = event[1];
    },

    changeLastDate(event) {
      this.submitData.StartLastExpeTime = event[0];
      this.submitData.EndLastExpeTime = event[1];
    },

    changeValidDate(event) {
      this.submitData.StartValidDate = event[0];
      this.submitData.EndValidDate = event[1];
    },

    changeCardNature(event) {
      let tArray = [];
      this.advancedQueryObject.cardNameList.forEach((item) => {
        if (item.NatureNo == event) {
          tArray.push(item);
        }
      });
      this.submitData.CardGuid = "";
      this.cardNameList = tArray;
    },

    changeCardKind(event) {
      let tArray = [];
      this.advancedQueryObject.cardNameList.forEach((item) => {
        if (item.KindGuid == event) {
          tArray.push(item);
        }
      });
      if (tArray.length > 0) {
        this.submitData.CardGuid = "";
        this.cardNameList = tArray;
      }
    },

    submitForm() {
      if (this.openDate !== null) {
        this.submitData.StartOpenDate =
          this.openDate.length > 0 ? this.openDate[0] : "";
        this.submitData.EndOpenDate =
          this.openDate.length > 0 ? this.openDate[1] : "";
      }

      if (this.lastDate !== null) {
        this.submitData.StartLastExpeTime =
          this.lastDate.length > 0 ? this.lastDate[0] : "";
        this.submitData.EndLastExpeTime =
          this.lastDate.length > 0 ? this.lastDate[1] : "";
      }

      if (this.validDate !== null) {
        this.submitData.StartValidDate =
          this.validDate.length > 0 ? this.validDate[0] : "";
        this.submitData.EndValidDate =
          this.validDate.length > 0 ? this.validDate[1] : "";
      }

      this.$emit("submitForm", this.submitData);
      this.closeAdvancedQueryDialog();
    },

    clearData() {
      this.openDate = [];
      this.lastDate = [];
      this.validDate = [];
    },
  },
};
</script>

<style lang="less" scoped>
.el-form-item__label {
  font-size: 12px !important;
}

.title {
  margin-bottom: 22px;
  font-weight: bold;
}

.date {
  width: 215px;
  .el-date-editor {
    width: 100%;
  }
}

.el-select {
  width: 215px;
}

.money {
  display: flex;
  width: 215px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  ::v-deep .el-input__inner {
    border: none !important;
  }
}
</style>
