<template>
  <div class="member-card-select">
    <div class="df-row">
      <div class="df-col">
        <!-- 选择门店 -->
        <el-select
          v-if="authType == 'H'"
          v-model="storesGuid"
          size="mini"
          placeholder="请选择门店"
          @change="onChangeSelect"
        >
          <el-option
            v-for="item in storesData"
            :key="item.StoresGuid"
            :label="item.StoresName"
            :value="item.StoresGuid"
          >
          </el-option>
        </el-select>
      </div>
      <div class="df-col">
        <el-input
          v-model="submitData.Word"
          size="mini"
          placeholder="请输入顾客姓名或手机号或卡号进行查询"
        >
        </el-input>
      </div>
      <div class="df-col">
        <el-select
          v-model="submitData.VipState"
          size="mini"
          placeholder="请选择卡状态"
        >
          <el-option
            v-for="item in advancedQueryObject.vipState"
            :key="item.Value"
            :label="item.Name"
            :value="item.Value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="df-col">
        <el-button @click="onSearchMemberCard" type="primary" size="mini">
          查询
        </el-button>
      </div>
      <div class="df-col">
        <el-button @click="onAdvancedQuery" type="primary" size="mini">
          高级查询
        </el-button>
      </div>
      <div class="df-col">
        <el-button @click="onClearAdvancedQuery" type="primary" size="mini">
          清空查询
        </el-button>
      </div>
      <div class="df-col">
        <DfExportButton ref="dfExportButton" :fileName="fileName" @export="onExportExcel"></DfExportButton>
      </div>
    </div>

    <!--  -->
    <el-row v-if="accountInfo.authRoleNo == '0001'">
      <el-col :span="5">
        <div class="grid-content">
          <span>￥{{ vipMaintFilter.TotalKeepBalance }}</span>
          <span>储值卡充值余额</span>
        </div>
      </el-col>
      <el-col :span="5">
        <div class="grid-content">
          <span>￥{{ vipMaintFilter.TotalDonaBalance }}</span>
          <span>储值卡赠送余额</span>
        </div>
      </el-col>
      <el-col :span="5">
        <div class="grid-content">
          <span>￥{{ vipMaintFilter.TotalPackBalance }}</span>
          <span>套卡总消耗余额</span>
        </div>
      </el-col>
      <el-col :span="5">
        <div class="grid-content">
          <span>￥{{ vipMaintFilter.TotalDebtBalance }}</span>
          <span>会员卡总欠款</span>
        </div>
      </el-col>
      <el-col :span="4">
        <div class="grid-content">
          <span>￥{{ vipMaintFilter.TotalInteBalance }}</span>
          <span>会员卡总积分</span>
        </div>
      </el-col>
    </el-row>

    <!--  -->
    <el-table
      :data="tableData"
      size="mini"
      border
      :max-height="tableMaxHeight"
      style="width: 100%"
      v-loading="loading"
    >
      <el-table-column type="selection" width="40"> </el-table-column>
      <el-table-column label="会员姓名" fixed width="90" prop="MemberName">
        <template slot-scope="scope">
          <span>{{ scope.row.MemberName }}</span>
          <i
            :class="scope.row.MemberSex ? 'el-icon-male' : 'el-icon-female'"
          ></i>
        </template>
      </el-table-column>
      <!-- <el-table-column label="性别" width="60" prop="MemberSexText">
      </el-table-column> -->
      <el-table-column label="手机号" width="120" prop="MemberPhone">
      </el-table-column>
      <el-table-column label="门店" width="200" prop="StoresName">
      </el-table-column>
      <el-table-column label="卡名称" width="140">
        <template slot-scope="scope">
          <div class="card-box">
            <span>{{ scope.row.CardName }}</span>
            <span>{{ scope.row.VipNo }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="卡属性" width="100" prop="NatureName">
      </el-table-column>
      <el-table-column label="卡分类" width="120" prop="KindName">
      </el-table-column>
      <el-table-column label="卡内余额" width="80">
        <template slot-scope="scope">
          <span class="vice">{{ scope.row.Balance }}</span>
        </template>
      </el-table-column>
      <el-table-column label="赠送余额" width="80">
        <template slot-scope="scope">
          <span class="vice">{{ scope.row.DonaBalance }}</span>
        </template>
      </el-table-column>
      <el-table-column label="消耗额" width="80">
        <template slot-scope="scope">
          <span class="vice">{{ scope.row.PackBalance }}</span>
        </template>
      </el-table-column>
      <el-table-column label="开卡日期" width="100" prop="OpenDate">
        <template slot-scope="scope">
          <div>{{ dateFormat(scope.row.OpenDate) }}</div>
        </template>
      </el-table-column>
      <el-table-column label="到期日期" width="100" prop="ValidDate">
        <template slot-scope="scope">
          <div>
            {{
              scope.row.ValidDate ? dateFormat(scope.row.ValidDate) : "无限期"
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="最后消费日期" width="100" prop="LastExpeTime">
        <template slot-scope="scope">
          <div>
            {{
              scope.row.LastExpeTime
                ? dateFormat(scope.row.LastExpeTime)
                : "无消费"
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="累计充值" width="100" prop="CumuKeepMoney">
      </el-table-column>
      <el-table-column label="累计赠送" width="100" prop="CumuDonaMoney">
      </el-table-column>
      <el-table-column
        label="累计消费"
        width="100"
        align="center"
        prop="CumuKeepExpeMoney"
      >
        <template slot-scope="scope">
          <div class="secondary">{{ scope.row.CumuKeepExpeMoney }}</div>
          <div>{{ scope.row.CumuDonaExpeMoney }}</div>
        </template>
      </el-table-column>
      <el-table-column label="短信通知" align="center" width="100">
        <template slot-scope="scope">
          <div class="secondary">{{ scope.row.SendKeepSms | whether }}</div>
          <div>{{ scope.row.SendExpeSms | whether }}</div>
        </template>
      </el-table-column>
      <el-table-column label="微信通知" align="center" width="100">
        <template slot-scope="scope">
          <div class="secondary">{{ scope.row.SendKeepWechat | whether }}</div>
          <div>{{ scope.row.SendExpeWechat | whether }}</div>
        </template>
      </el-table-column>
      <el-table-column label="备注" width="180" prop="VipNote">
      </el-table-column>
      <el-table-column prop="address" label="操作" fixed="right" width="180">
        <template slot-scope="scope">
          <el-button
            v-show="scope.row.VipState == 1"
            class="defuat-button"
            type="danger"
            size="mini"
            :disabled="deleteDisabled"
            @click="onDeleteVipCard(scope.row.VipGuid, '删除')"
            >删 除</el-button
          >

          <el-button
            v-show="scope.row.VipState != 1"
            class="defuat-button"
            type="warning"
            size="mini"
            :disabled="recoverDisabled"
            @click="onDeleteVipCard(scope.row.VipGuid, '恢复')"
            >恢 复</el-button
          >

          <el-popover
            v-show="scope.row.VipState == 1"
            popper-class="popper"
            placement="bottom"
            style="min-width: 0"
            trigger="hover"
          >
            <div class="popover-button-box">
              <el-button
                type="primary"
                size="mini"
                :disabled="loseDisabled"
                @click="onDeleteVipCard(scope.row.VipGuid, '挂失')"
              >
                挂 失
              </el-button>
              <el-button
                type="warning"
                size="mini"
                :disabled="cancelDisabled"
                @click="onDeleteVipCard(scope.row.VipGuid, '注销')"
              >
                注 销
              </el-button>
              <el-button
                type="info"
                size="mini"
                :disabled="disableDisabled"
                @click="onDeleteVipCard(scope.row.VipGuid, '停用')"
              >
                停 用
              </el-button>
            </div>
            <el-button class="more" size="mini" slot="reference"
              >更 多</el-button
            >
          </el-popover>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="submitData.PageIndex"
      :page-sizes="[12, 20, 30, 40, 50, 100]"
      :page-size="submitData.PageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalRecord"
    >
    </el-pagination>

    <AdvancedQuery ref="AdvancedQuery" @submitForm="onSubmitForm">
    </AdvancedQuery>
  </div>
</template>

<script>
import member from "@/api/member.js";
import Vip from "@/api/vip.js";
import AdvancedQuery from "./components/advanced-query.vue";
export default {
  components: { AdvancedQuery },
  data() {
    return {
      input: "",
      tableMaxHeight: "",
      tableData: [],
      currentPage4: 1,
      submitData: {
        StoresGuids: [],
        MemberSex: -1,
        CardGuid: "",
        StartOpenDate: "",
        EndOpenDate: "",
        StartLastExpeTime: "",
        EndLastExpeTime: "",
        KindGuid: "",
        StartPackBalance: -1,
        EndPackBalance: -1,
        StartCardBalance: -1,
        EndCardBalance: -1,
        StartDonaBalance: -1,
        EndDonaBalance: -1,
        StartCumuKeepMoney: -1,
        EndCumuKeepMoney: -1,
        StartCumuExpeMoney: -1,
        EndCumuExpeMoney: -1,
        StoresShare: -1,
        ValidDateMode: 0,
        StartValidDate: "",
        EndValidDate: "",
        PageSize: 12,
        PageIndex: 1,
        Word: "",
        VipState: 1,
        NatureNo: "",
        HidePackOver: 0
      },
      storesGuid: "",
      authType: "",
      fileName: '',
      storesData: [],
      totalRecord: 0,
      accountInfo: {},
      advancedQueryObject: {},
      vipMaintFilter: {},
      loading: false,
      deleteDisabled: false,
      recoverDisabled: false,
      loseDisabled: false,
      cancelDisabled: false,
      disableDisabled: false,
    };
  },

  filters: {
    whether(param) {
      return param == 0 ? "否" : "是";
    },
  },

  created() {
    let accountInfo = JSON.parse(window.sessionStorage.getItem("accountInfo"));
    this.accountInfo = accountInfo
    this.getResize();
  },

  mounted() {
    window.addEventListener("resize", this.getResize);
    let accountInfo = JSON.parse(window.sessionStorage.getItem("accountInfo"));
    this.authType = accountInfo.authType;
    if (this.authType == "H") {
      this.storesData = [
        { StoresGuid: "", StoresName: "全部门店" },
        ...accountInfo.manageStoresList,
      ];
    }

    this.queryCondition();
    this.initPageData();
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  methods: {
    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight } = this.util.getDocumentElementInfo();
      this.tableMaxHeight = clientHeight - (100 + 48 + 64 + (this.accountInfo.authRoleNo == '0001' ? 48 : 0));
    },

    async initPageData() {
      this.loading = true;
      try {
        let { data } = await Vip.getVipMaintItemList(this.submitData);
        let { vip, vipMaintFilter } = data;
        this.tableData = vip;
        this.totalRecord = vipMaintFilter.TotalRecord;
        this.vipMaintFilter = vipMaintFilter;
      } catch (e) {}
      this.loading = false;
    },

    async queryCondition() {
      try {
        let { data } = await Vip.getInitVipSelectData();
        this.advancedQueryObject = data;
      } catch (e) {}
    },

    // 导出表格回调函数
    async onExportExcel(){
      try {
        let { data } = await Vip.getVipMaintItemList(this.submitData, true, this.totalRecord);
        if(data){
          this.fileName = data
        }
      } catch (e) {
        console.log(e);
      }
    },

    onChangeSelect(event) {
      this.submitData.StoresGuids = event ? [event] : [];
    },

    dateFormat(param) {
      return this.util.dateFormat("YYYY-mm-dd", new Date(param * 1000));
    },

    onSearchMemberCard() {
      this.submitData.PageIndex = 1;
      this.submitData.PageSize = 12;
      //this.clearCondition();
      this.initPageData();
    },

    onAdvancedQuery() {
      this.$refs.AdvancedQuery.openAdvancedQueryDialog(
        this.submitData,
        this.advancedQueryObject
      );
    },

    onClearAdvancedQuery() {
      this.submitData = {
        MemberSex: -1,
        CardGuid: "",
        StartOpenDate: "",
        EndOpenDate: "",
        StartLastExpeTime: "",
        EndLastExpeTime: "",
        KindGuid: "",
        StartPackBalance: -1,
        EndPackBalance: -1,
        StartKeepBalance: -1,
        EndKeepBalance: -1,
        StartDonaBalance: -1,
        EndDonaBalance: -1,
        StartCumuKeepMoney: -1,
        EndCumuKeepMoney: -1,
        StartCumuExpeMoney: -1,
        EndCumuExpeMoney: -1,
        StoresShare: -1,
        ValidDateMode: 0,
        StartValidDate: "",
        EndValidDate: "",
        PageSize: 12,
        PageIndex: 1,
        Word: "",
        VipState: 0,
        NatureNo: "",
        HidePackOver: 0
      };
      this.$refs.AdvancedQuery.clearData();
    },

    onSubmitForm(event) {
      this.submitData = event;
      this.submitData.PageIndex = 1;
      this.submitData.PageSize = 12;
      this.initPageData();
    },

    onDeleteVipCard(vipGuid, text) {
      this.$confirm(`是否${text}该会员卡`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: text == "恢复" ? "" : "warning",
      }).then(async (res) => {
        try {
          let submitData = {
            vip_guid: vipGuid,
          };
          let data;

          if (text == "删除") {
            this.deleteDisabled = true;
            data = await Vip.deleteVip(submitData);
          } else if (text == "挂失") {
            this.loseDisabled = true;
            data = await Vip.loseVip(submitData);
          } else if (text == "注销") {
            this.cancelDisabled = true;
            data = await Vip.cancelVip(submitData);
          } else if (text == "停用") {
            this.disableDisabled = true;
            data = await Vip.disableVip(submitData);
          } else {
            this.recoverDisabled = true;
            data = await Vip.recoverVip(submitData);
          }

          let { errcode } = data;
          if (errcode == 0) {
            this.$message.success(`${text}成功`);
            this.initPageData();
          }
        } finally {
          this.deleteDisabled = false;
          this.disableDisabled = false;
          this.cancelDisabled = false;
          this.loseDisabled = false;
          this.recoverDisabled = false;
        }
      });
    },

    handleSizeChange(event) {
      this.submitData.PageSize = event;
      this.submitData.PageIndex = 1;
      this.initPageData();
    },

    handleCurrentChange(event) {
      this.submitData.PageIndex = event;
      this.initPageData();
    },

    clearCondition() {
      this.submitData = {
        MemberSex: -1,
        CardGuid: "",
        StartOpenDate: "",
        EndOpenDate: "",
        StartLastExpeTime: "",
        EndLastExpeTime: "",
        KindGuid: "",
        StartCardBalance: -1,
        EndCardBalance: -1,
        StartDonaBalance: -1,
        EndDonaBalance: -1,
        StartCumuKeepMoney: -1,
        EndCumuKeepMoney: -1,
        StartCumuTotalMoney: -1,
        EndCumuTotalMoney: -1,
        StoresShare: -1,
        ValidDateMode: 0,
        StartValidDate: "",
        EndValidDate: "",
        PageSize: 12,
        PageIndex: 1,
        Word: "",
        HidePackOver: 0
      };
    },
  },
};
</script>

<style lang="less" scoped>
.member-card-select {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .df-row {
    .el-select {
      width: auto;
    }
  }

  .el-row {
    margin-bottom: 20px;
  }

  .secondary {
    border-bottom: 1px solid #ebeef5;
  }

  .el-table {
    i {
      font-size: 16px;
      margin-left: 2px;
    }
    .el-icon-male {
      color: #6aabdb;
    }

    .el-icon-female,
    .vice {
      color: tomato;
    }

    .card-box {
      .flex-col;
      align-items: unset;
    }
  }

  .grid-content {
    height: 44px;
    line-height: 44px;
    text-align: center;
    background-color: #f9fafc;
    //margin: 20px 0;
    span:first-child {
      margin-right: 10px;
    }
    span:last-child {
      color: #8492a6;
    }
  }

  .el-pagination {
    float: right;
    margin-top: 20px;
  }
}

::v-deep .defuat-button {
  margin-right: 10px;
}

.popover-button-box {
  display: flex;
  flex-direction: column;
  padding: 5px 0;
  margin: 0 !important;

  ::v-deep .el-button {
    // width: 100px;
    margin: 5px 5px;
  }
}
</style>
